import { useState } from "react"
import "./App.css"

function App() {
	const [open, setOpen] = useState(false)
	return (
		<body className="">
			<head>
				<script
					defer
					data-domain="nicolasmahren.com"
					src="https://plausible.io/js/plausible.js"
				></script>
			</head>
			<div
				style={{
					perspective: "400px",
				}}
				onClick={() => {
					if (open) setOpen(false)
				}}
				className=" h-screen h-screen-ios w-screen bg-black flex flex-col items-center justify-center"
			>
				<div
					style={{
						transform: open ? "rotateX(90deg)" : "rotateX(0deg)",
						transformOrigin: "bottom",

						transformStyle: "preserve-3d",
					}}
					className="absolute inset-0 bg-orange-500  transition-all duration-700 ease-in flex flex-col items-center justify-between p-4 md:p-10"
				>
					<div />
					<div
						className={`${
							open ? "cursor-zoom-out" : "cursor-zoom-in"
						} flex flex-col items-center`}
						onClick={() => setOpen(!open)}
					>
						<h1 className="">Nicolas Mahren</h1>
						<div className="flex flex-col md:flex-row justify-between self-stretch">
							<h2>Concept</h2>
							<h2>Development</h2>
							<h2>Architecture</h2>
						</div>
					</div>
					<div className="flex space-x-6">
						<a href="https://de.linkedin.com/in/nmahren" target="__blank">
							<h5>◦ LinkedIn</h5>
						</a>
						<a href="mailto:nicolas-mahren@posteo.de">
							<h5>◦ Email</h5>
						</a>
						<a href="https://www.instagram.com/nico_m125/" target="__blank">
							<h5>◦ Instagram</h5>
						</a>
					</div>
				</div>
				<h2 className="text-orange-400">Portfolio coming soon!</h2>
			</div>
		</body>
	)
}

export default App
